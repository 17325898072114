<template>
  <div class="page-item-wrap">
    <div class="select-wrap" v-click-outside="OutTerritory" ref="select">
      <div class="select" @click="toggleEvent()" :class="{ active: toggle }">
        {{ ViewSelectDefault }}
      </div>
      <div class="select-list" v-if="toggle">
        <ul>
          <li v-for="(list, idx) in list" :key="`selectList_${idx}`">
            <input
              type="checkbox"
              :name="list.text"
              :value="list.value"
              :id="`chk_${idx}`"
              v-model="selectValue"
              @click="valueChange($event, idx)"
            />
            <label :for="`chk_${idx}`"> {{ list.text }}</label>
          </li>
        </ul>
      </div>
    </div>

    <div class="SelectedList">
      <div style="border-bottom: 1px solid #000">
        <h1>VALUE</h1>
        <p v-for="(list, idx) in selectValue" :key="`selectedVal_${idx}`">
          {{ list }}
        </p>
      </div>
      <div>
        <h1>TEXT</h1>
        <p v-for="(list, idx) in selectText" :key="`selectedTxt_${idx}`">
          {{ list }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import vClickOutside from 'v-click-outside'
export default {
  data() {
    return {
      selectValue: [],
      selectText: [],
      ViewSelectDefault: '선택',
      toggle: false,
      list: [
        {
          value: 'Business',
          text: '비즈니스 호텔',
        },
        {
          value: 'convention',
          text: '컨벤션',
        },
        {
          value: 'five-star',
          text: '5성급 호텔',
        },
        {
          value: 'four-star',
          text: '4성급 호텔',
        },
        {
          value: 'hostel',
          text: '호스텔',
        },
        {
          value: 'motel',
          text: '모텔',
        },
        {
          value: 'one-star',
          text: '1성급 호텔',
        },
        {
          value: 'Pension',
          text: '펜션',
        },
        {
          value: 'Resort',
          text: '리조트',
        },
        {
          value: 'Restaurant',
          text: '레스토랑',
        },
        {
          value: 'three-star',
          text: '3성급 호텔',
        },
        {
          value: 'two-star',
          text: '2성급 호텔',
        },
        {
          value: 'wedding-hall',
          text: '웨딩홀',
        },
      ],
    }
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  watch: {
    selectText() {
      console.log(this.selectText.length)
      if (this.selectText.length === 1) {
        this.ViewSelectDefault = this.selectText[0]
      } else if (this.selectText.length > 1) {
        this.ViewSelectDefault =
          this.selectText[0] +
          ` ` +
          '외' +
          ' ' +
          (this.selectText.length - 1) +
          '건'
      }
    },
  },
  methods: {
    valueChange(event) {
      const arr = this.selectText
      const name = event.target.name
      const ix = arr.indexOf(name)
      if (event.target.checked) {
        this.selectText.push(event.target.name)
      } else {
        arr.splice(ix, 1)
      }
    },
    toggleEvent() {
      this.toggle = !this.toggle
    },
    OutTerritory() {
      this.toggle = false
    },
  },
}
</script>
<style scoped>
.select-wrap {
  width: 200px;
  margin: 100px auto;
}
.select {
  width: 100%;
  display: block;
  line-height: 42px;
  box-sizing: border-box;
  background: #fff url(~assets/image/bg/bg-select03.svg) no-repeat right 15px
    top 50% / 13px 6px;
}
.select.active {
  background: #fff url(~assets/image/bg/bg-select03_up.svg) no-repeat right 15px
    top 50% / 13px 6px;
}
.select-list {
  border: 1px solid #ddd;
  background-color: #fff;
  color: #666;
  box-shadow: #0000004a 0px 3px 8px 0px;
  box-sizing: content-box;
  height: 200px;
  overflow: hidden;
  overflow-y: auto;
}
/* .select-list ul {
  padding-left: 15px;
  padding-right: 20px;
} */
.select-list ul li {
  padding-top: 5px;
  padding-bottom: 5px;
}
.select-list ul li:hover {
  background-color: #1e90ff;
}
.select-list ul li:hover label {
  color: #fff;
}
.select-list ul li input {
  margin-left: 15px;
  margin-right: 5px;
}
.select-list ul li label {
  margin-right: 20px;
  display: block;
}
.select-list ul li input[type='checkbox']:checked + label {
  color: #ff6831;
}
.select-list ul li:nth-last-child(1) {
  padding-bottom: 5px;
}
.select-list ul li label {
}
.SelectedList {
  width: 500px;
  min-height: 100px;
  height: auto;
  margin: 100px auto;
  border: 1px solid #666;
  border-radius: 8px;
  overflow: hidden;
}
.SelectedList div {
  width: 100%;
  min-height: 30px;
  height: auto;
  display: flex;
  flex-wrap: wrap;
}
.SelectedList div h1 {
  width: 100%;
  height: 30px;
  text-align: center;
  border-bottom: 1px double #000;
  line-height: 30px;
  background-color: #fff;
  color: #000;
  font-weight: bold;
}
.SelectedList div p {
  width: auto;
  height: 30px;
  padding: 0 10px;
  line-height: 30px;
  margin: 10px 5px;
  background-color: #e5e5e5;
  border-radius: 4px;
  text-align: center;
  color: #666;
}
</style>
